/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .features-grid {
      grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .features-grid {
      grid-template-columns: 1fr; /* One column on very small screens */
    }
  }
  
/* Button Styles */
.accountMenu button,
.accountMenu button:hover,
.accountMenu button:active,
.accountMenu button:focus-visible,
.navButton,
.navButton:hover,
.navButton:active,
.navButton:focus-visible,
.trialButton,
.trialButton:hover,
.trialButton:active,
.trialButton:focus-visible {
  background-color: var(--primary-color) !important;
  border: none;
  color: var(--highlight-color) !important;
}

.trialButton,
.trialButton:hover {
  background-color: var(--highlight-color) !important;
  color: var(--primary-color);
}

/* Primary Button Styles */
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus-visible {
  background-color: var(--primary-color) !important;
  color: var(--highlight-color);
  border-radius: 3%;
  font-size: 1rem;
  border: 1px solid var(--primary-color) !important;
  ;
  padding: 0.3rem 1.5rem;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus-visible {
  color: var(--primary-color) !important;
  background-color: var(--highlight-color);
  border: 1px solid var(--primary-color) !important;
  ;
  border-radius: 3px;
  font-size: 1rem;
  padding: 0.3rem 1.5rem;
}

.back-button,
.continue-button {
  width: 8rem;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  left: calc((100vw - 150%) / 2 + 50%);
  /* Adjust this formula according to your layout */
  transform: translateX(-50%);
  z-index: 1000;
  width: calc(100vw - 60%);
}

.copyToClipboardButton {
  position: absolute;
  /* Absolute position */
  top: 0;
  /* Align to the top of the container */
  right: 0;
  /* Align to the right of the container */
  z-index: 10;
  /* Ensure it's above other content */
  margin-right: 5px;
  background-color: #f5f5f5;
  border: none;
  color: black;
}

.copyToClipboardButton:hover {
  background-color: white;
  color: black;
}

.removeImageButton,
.removeImageButton:hover {
  background: grey !important;
  color: white !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none !important;
  /* Remove the border if it's not part of your design */
  position: absolute;
  top: 0rem;
  /* Replace 'top-0' with a specific value */
  right: 0.6rem;
  /* Replace 'end-0' with 'right' and a specific value */
  margin: 0.5rem;
  /* Replace 'm-2' with a specific value */
}

.removeImageButton:hover {
  background: darkgrey;
  /* Example hover effect */
}

.trash-button,
.trash-button:hover {
  background-color: white !important;
  color: #747373;
  border: none !important;
  padding: 2px;
}

.plus-button,
.plus-button:hover {
  border: none !important;
  padding: 8px 12px;
}

.info-icon {
  cursor: pointer;
  padding-left: 3px;
  color: #373333;
  font-size: 1rem;
}


/* Content Layout Styles */
.landing-page-content,
.standard-page-content {
  position: absolute;  
  top: 60px; /* Height of the navbar */  
  bottom: 0; /* Anchor to the bottom of the viewport */  
  width: 100%;  
  overflow-y: auto; /* Allows content to scroll within the div if needed */
  background-color: #f7f6f4;
  padding: 2rem 2.5rem;
}

.landing-page-content {  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  height: calc(100vh - 60px); /* Adjust the height to account for the navbar */  
  background-color: #f7f6f4;  
}  

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns of equal width */
  grid-gap: 1rem; /* Space between tiles */
  width: 100%;
  max-width: 1200px; /* Maximum width of the grid */
}

/* Generator Page Columns */
#leftColumn,
#rightColumn {
  padding: 1rem 5rem;
  flex: 1; /* Each column will take up equal width */  
  overflow-y: auto; /* Allows each column to scroll independently */ 
}

#leftColumn {
  background-color: #f5f6f7;
  overflow-y: scroll;
  padding-bottom: 3rem;
  position: relative;
}

#rightColumn {
  background-color: #ffffff;
}

/* Primary Section Styles */
#primarySection {
  display: flex;  
  flex-wrap: nowrap; /* Prevents the columns from wrapping */  
}

#primarySection > div {
  height: 100%;
}



.account-details {
  padding-top: 1rem;
}

.contact-page-content {
  padding: 1rem 0rem;
}
.contact-page-content input, .contact-page-content textarea {
  margin-bottom: 1rem;
}
.contact-submit-button {
  width: 100%
}

.contentEditableContainer {  
  position: relative; /* Ensure the child absolute element is positioned relative to this container */  
  margin-top: 1rem;
}  

/* Generated Text Styles */
.generatedText {
  font-size: 14px;
  padding: 2rem;
  height: 100%;
  /* outline: 1px dashed var(--primary-color); */
  outline: 1px dashed #dcdbdb;
  background-color: #f5f6f7;
}

/* Add this to your CSS, possibly in the miscellaneous.css file */
.text-align-responsive {
  text-align: left; /* Default to left alignment */
}

@media (max-width: 767px) {
  .text-align-responsive {
    text-align: center; /* Center text on small screens */
  }
}

/* Landing Page Responsive Styles */  
@media (max-width: 991px) {  
  .landing-page-content {  
    justify-content: flex-start; /* Align content to the top on smaller screens */  
    padding-top: 3rem; /* Add additional padding to the top */  
    min-height: calc(100vh - 60px); /* Adjust the height to account for the navbar */  
  }  
  
  /* Adjust grid to single column */  
  .features-grid {  
    grid-template-columns: 1fr;  
    padding: 0 1rem; /* Add padding for smaller screens */  
  }  
  
  /* Hide the image on small screens */  
  .landing-page-content .img-fluid {  
    display: none;  
  }  
}  

.hero-heading {
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.1;
  font-family: Inter F,sans-serif;
  text-align: center;
}

.sub-headline {
  color: #4f4f4f;
  line-height: 1.6;
  margin-top: 24px;
  text-align: center;
}
  
/* Responsive typography */  
@media (min-width: 992px) {  
  .hero-heading {  
    font-size: 64px;  
  }  
  
  .sub-headline {  
    font-size: 20px;  
  }  
}  

@media (max-width: 991px) {  
  .hero-heading {  
    font-size: 64px;  
  }  
  
  .sub-headline {  
    font-size: 20px;  
  }  
}  
  
@media (max-width: 767px) {  
  .hero-heading {  
    font-size: 52px;  
  }  
  
  .sub-headline {  
    font-size: 18px;  
  }  
}  

@media (max-width: 570px) {  
  .hero-heading {  
    font-size: 42px;  
  }  
  
  .sub-headline {  
    font-size: 17px;  
    text-align: center;
  }  
}  
  
@media (max-width: 479px) {  
  .hero-heading {  
    font-size: 32px;  
  }  
  
  .sub-headline {  
    font-size: 16px;  
  }  
}  


  
/* Adjust form control and button stacking on very small screens */  
@media (max-width: 767px) {  
  .btn-register {  
    width: 100%;  
    margin-top: 1rem; /* Add space between input and button */  
  }  
}  

.feature-img {
  height: '400px';
  object-fit: 'contain';
  width: '100%';
}

@media (max-width: 767px) {
  .standard-page-content {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .feature-img {
    height: auto;
  }
}
.preview-heading {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    line-height: 28px;
    letter-spacing: 0.1px;
}

.preview-price-pcm {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.preview-price-pw {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #6c6d7f;
    margin-left: 10px;
}

.preview-letting-detail-title {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 1rem;
}

.preview-letting-detail-value {
    padding-left: 3px;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.preview-divider {
    border-bottom: 1px solid #dedee2;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
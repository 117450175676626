/* pricing.css */  
.pricing-tile {  
    border: 1px solid #ddd;  
    padding: 20px;  
    border-radius: 4px;  
    text-align: center;  
    background-color: #f8f9fa;  
  }  
    
  .pricing-header {  
    margin-bottom: 20px;  
  }  
    
  .pricing-title {  
    margin-bottom: 10px;  
    font-size: 1.5rem;  
    color: #333;  
  }  
    
  .pricing-cost {  
    font-size: 2.5rem;  
    font-weight: bold;  
    color: #007bff;  
  }  
    
  .pricing-frequency {  
    font-size: 1rem;  
    font-weight: normal;  
    color: #666;  
  }  
    
  .pricing-description {  
    font-size: 1rem;  
    color: #666;  
    margin-bottom: 20px;  
  }  
    
  .pricing-features {  
    list-style: none;  
    padding: 0;  
  }  
    
  .pricing-features .feature {  
    text-align: left;  
    margin-bottom: 10px;  
    font-size: 0.9rem;  
  }  
    
  .pricing-features .tick {  
    color: green;  
    margin-right: 5px;  
  }  
  
/* Fonts and Typography */
@font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope-VariableFont_wght.ttf') format('truetype-variations');
    font-weight: 200 900;
  }
  
  body {
    font-family: "Manrope", sans-serif;
  }
  
  /* Typography Styles */
  .standard-page-content h1 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--text-color);
  }
  
  h2 {
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 0.6rem;
    border-bottom: 1px solid lightgray;
    padding-top: 1.5rem;
    color: var(--primary-color);
  }

  h3 {
    font-size: 1rem;
    font-weight: 600;

    padding-top: 1rem;
  }

.toolbar-container {  
  display: flex; /* Establishes a flex container */  
  justify-content: flex-end; /* Positions children to the right */  
  align-items: center; /* Vertically aligns children in the middle */  
  padding-bottom: 0.6rem; /* Matches the previous h2 bottom padding */ 
} 
  
.toolbar-button {  
    padding: 0.3rem 0.5rem; /* Adjust padding as needed */  
    background-color: white; /* Example background color - use your theme color */  
    color: #848485; /* Text color for the button */  
    cursor: pointer; /* Changes the cursor to pointer on hover */  
    border: 1px solid white;
} 

.toolbar-button:hover {  
    color: var(--primary-color); /* Changes the text color on hover */  
    border: 1px solid var(--primary-color); /* Adds a border on hover */
}

.toolbar-button:disabled {
    cursor: not-allowed;
    color: #848485;
    border: none;
}

.toolbar-button:active {
  border: 1px solid white !important;
  color: #848485 !important
}
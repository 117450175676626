/* Miscellaneous Styles */
/* Add any styles that don't fit into other categories here */
.spinner-container {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    z-index: 10; /* Ensure the spinner is above the select input */
  }
  
  .spinner {
    animation: spin 1s linear infinite; /* Define the spinning animation */
    font-size: 1rem; /* Adjust the size of the spinner icon as needed */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
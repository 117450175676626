/* tiles.css */  
.feature-tile {  
    display: flex;  
    flex-direction: column;  
    align-items: center;  
    text-align: center;  
    border-radius: 15px;  
    overflow: hidden;  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
    background-color: #ffffff;  
    transition: transform 0.2s; /* Optional: Adds a subtle hover effect */  
    flex: 1 0 auto; /* Grow to fill the container, don't shrink below content size */  
    width: 100%; /* Ensure it takes the full width */  
}  
  
.feature-image {  
    width: 100%;  
    height: 200px; /* Set a fixed height for all images */  
    object-fit: cover; /* Cover the area, maintain aspect ratio, might crop the image */  
    border-top-left-radius: 15px;  
    border-top-right-radius: 15px;  
}  
  
.feature-title {  
    margin: 15px 0;  
    font-size: 1.5em;  
}  
  
.feature-description {  
    padding: 0 15px 15px; /* Add padding at the bottom to push content down */  
    flex-grow: 1; /* This allows the description to expand and push down any elements below it */  
}  
  
.features-page-introduction {  
    margin-bottom: 2em;  
}  
  
.call-to-action {  
    text-align: center;  
    margin-top: 30px;  
}  
  
/* Responsive layout for tablets and smaller devices */  
@media (max-width: 991px) {  
    .feature-tile {  
        margin-bottom: 20px; /* Space below each tile for when they wrap */  
    }  
    .container {  
        padding: 0px;  
    }
}  
  
/* Responsive layout for mobile devices */  
@media (max-width: 767px) {  
    .feature-tile {  
        margin-bottom: 20px; /* Space below each tile for when they stack */  
    }  
}  

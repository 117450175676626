.tab-content {
    background-color: white;
    padding: 1rem;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}


/* Listing Tabs */
.listing-tabs .nav-item .nav-link.active {  
    color: var(--primary-color);
}  

.listing-tabs .nav-item .nav-link {  
    color: black;
}  

.listing-tabs .nav-item .nav-link:hover,  
.listing-tabs .nav-item .nav-link:focus {  
    color: var(--primary-color);
}  
  

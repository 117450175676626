/* SkeletonLoader.css */  
  
.skeleton-loader {  
    background-color: #ddd;  
    border-radius: 4px;  
    margin: 10px 0;  
    overflow: hidden;  
    position: relative;  
  }  
    
  .skeleton-loader::after {  
    content: '';  
    display: block;  
    height: 100%;  
    width: 100%;  
    transform: translateX(-100%);  
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);  
    animation: load 1.2s infinite;  
  }  
    
  @keyframes load {  
    100% {  
      transform: translateX(100%);  
    }  
  }  

  /* Fade-in animation */  
  @keyframes fadeIn {  
    from {  
      opacity: 0;  
    }  
    to {  
      opacity: 1;  
    }  
  }  
    
  .fade-in {  
    animation: fadeIn 1s ease-out forwards;  
  }  
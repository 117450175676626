/* Navigation Bar Styles */

.navbar {
  background-color: var(--primary-color);
  z-index: 1000;
  padding: 10px 3px;
}

.navbar-brand,
.nav-link {
  color: var(--highlight-color);
}

.navbar-brand:hover, .navbar-brand:focus {
  color: white;
}

.nav-container .nav-link:hover, .nav-container .nav-link:focus, .nav-container .nav-link.show, .nav-container .nav-link.active {
  color: white;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.navbar-toggler {
  border: none;
  outline: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler-icon {
  font-size: 1.5em;
}

/* Style for the dropdown when collapsed */
.navbar-collapse {
  background-color: var(--primary-color);
}

.btn.signInButton:hover {
  color: white !important;
  text-decoration: underline;
}

/* Media query for mobile devices */
@media (max-width: 991.98px) {
  .navbar {
    height: auto;
    position: relative;
  }

  .accountMenu .dropdown-menu {
    position: static;
    float: none;
  }

  .navButton {
    width: 100%;
    margin-top: 1rem;
  }

  .navbar-collapse {
    display: flex;
    flex-direction: column;
  }



  .btn.signInButton {
    order: -1;
    /* This will place it above all items with the default order of 0 */
    text-align: left;
    /* Align the text to match other menu items */
    width: 100%;
    /* Optional: Adjust width as needed */
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border: none !important;
    /* Ensure this overrides other border styles */
  }

  .btn.signInButton:focus-visible,
  .btn.signInButton:hover {
    border: none !important;
    color: white !important;
    text-decoration: underline;
  }

  /* Ensure other nav-links are left-aligned */
  .nav-link {
    text-align: left;
  }
}

/* Navbar Toggler */

.navbar-toggler {  
  --bs-navbar-toggler-icon-bg: none; /* Unset the Bootstrap background image */  
}  
  
.navbar-toggler-icon {  
  cursor: pointer;  
  width: 30px;  
  height: 24px;  
  position: relative;  
  display: inline-block; /* Changed to inline-block */  
}  
  
.navbar-toggler-icon span {  
  display: block;  
  position: absolute;  
  height: 2px;  
  width: 100%;  
  background: currentColor;  
  border-radius: 1px;  
  opacity: 1;  
  left: 0;  
  transition: all 0.3s ease-in-out;  
}  
  
.navbar-toggler-icon span:nth-of-type(1) {  
  top: 0;  
}  
  
.navbar-toggler-icon span:nth-of-type(2) {  
  top: 10px;  
}  
  
.navbar-toggler-icon span:nth-of-type(3) {  
  top: 20px;  
}  
  
.navbar-toggler-icon.open span:nth-of-type(1) {  
  top: 10px;  
  transform: rotate(135deg);  
}  
  
.navbar-toggler-icon.open span:nth-of-type(2) {  
  opacity: 0;  
  left: -50px;  
}  
  
.navbar-toggler-icon.open span:nth-of-type(3) {  
  top: 10px;  
  transform: rotate(-135deg);  
}  

.navbar-toggler {
  color: white;
}

@media (min-width: 992px) {
  .nav-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .signInButton {  
    white-space: nowrap; /* Prevent text from wrapping */  
    margin-left: auto;  /* Align the button to the right */  
  }  
}
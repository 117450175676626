.dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto;
}

/* Form Section Styles */
.tr-section {
  margin-bottom: 32px;
}

.tr-heading,
.tr-subheading {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 8px;
}

.tr-subheading-text {
  color: #6b6d7c;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}

.tr-form-group {
  border: 1px solid #efeff5;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 24px;
}

.form-subtitle {
  margin-bottom: 0px;
  font-size: 0.9rem;
}

.col-form-label {
  color: #0d0d0d96;
  font-weight: 100;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 3px;

}

.text-input-alert {
  width: fit-content;
  font-size: 14px;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.image-upload, .image-upload:hover, .image-upload:active {
  width: 100%;
  border: 1px dashed #5928e5;
  color: #898888 !important;
  background-color: white !important;
  padding: 1rem;
}

.property-listing-form {
  background-color: white;
  border: 1px solid lightgray;
  padding: 1rem 2rem;
  padding-bottom: 2rem
}

.icon-magic {
  margin-right: 3px;
  vertical-align: middle;
}
.disclaimer {  
  background-color: #f8f9fa;  
  border-left: 4px solid var(--primary-color);  
  padding: 10px; 
}

.listing-feature {
  padding-left: 0px;
  border: none;
  padding-top: 0px;
  padding-bottom: 4px;
  margin-left: 1.2rem;
}

.listing-feature input {
  border: none;
  padding-left: 2px;
}

.listing-template-label {
  margin-bottom: .5rem;
  font-size: 16px;
  color: #4d4d4d;
  font-weight: 400;
  padding-left: 5px;
}

.feature-icon {
  font-size: 0.6rem;
}

.required-input {
  color: red;
  padding-left: 3px;
}

.feedbackModal .modal-header {
  padding-bottom: 0px;
  border-bottom: none;
}

.feedbackModal .modal-footer {
  padding-top: 0px;
  border-top: none;
}

.feedbackModal .modal-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 8px;
}

.feedbackModal .modal-body {
  padding-top: 0px;
  padding-bottom: 0px;
}
.feedbackModal .modal-body p {
  font-size: 0.9rem;
  color: #363639;
}

.feedbackModal .modal-body textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 16px;
}

.input-email {
  border-radius: 0px;
}

.form-interest {
  padding: 25px 40px;
}

@media (max-width: 991.98px) {
  .form-interest {
    padding: 10px 5px;
  }
}